.userlist {
  margin-top: 10px;
}

.cardimage {
  float: right;
}

.small {
  font-size: 10px;
  display: block;
}

.smallTest{
  display: block;
}

.formtest {
  font-size: 12px;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: rgb(246, 247, 249);
  color: black;
  text-align: center;
}

.scrollable {
  margin-top: 10px; /* place it under navbar */
  height: 75vh;
  overflow: scroll;
  padding-bottom: 48px; /* compensate margin top */
}

.signinCard {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.displayLength {
  float: right;
}

.cardsizeitem {
  height: 175px;
}

.filterbutton {
  display: none;
}

.test{
  display: table;
}

.filtercollapse {
  display: table;
  max-width: 100% !important;
  width: 100% !important;
}

.navbar {
  background-color: rgb(246, 247, 249);
}

.tableselect {
  max-width: 33%;
  width: 33%;
}
.searchBar {
  display: inline-block;
  width: 80%
}

.nextTo {
  display: inline-block;
  margin-left: 15px;
}

.buttonicons.btn-primary {
  background-color: transparent !important;
  border: none !important;
  margin-left: 70px;
}

.buttonicons.btn-primary:hover {
  background-color: transparent !important;
  border: none !important;
}


.btn-primary:hover{
  background-color: rgb(233,90,12) !important;
  border: #ff8d3f !important;;
}

.btn-primary {
  background-color: rgb(233,90,12) !important;
  border: #ff8d3f !important;;
}

.signInButton {
  background-color: rgb(233,90,12) !important;
  border: #ff8d3f !important;;
}

.form-control:focus {
  border-color: #ff8d3f !important;
  box-shadow: 0 0 0 0.1rem rgba(255,141,63, 1);
}

.signInButton:hover {
  background-color: #ff8d3f !important;;
  border: #ff8d3f !important;;
}

a {
  color: rgb(233,90,12) !important;
  margin-left: 5px;
}

a:hover {
  color: #ff8d3f !important;;
}

.mr-right {
  margin-left: 10px;
}

.page-item.active .page-link{
  background-color: #ff8d3f;
  border-color: #ff8d3f;
}


@media only screen and (max-width: 500px) {
  .filterbutton {
    display: table;
  }

  .filtercollapse {
    display: none !important;
  }
}

table thead .caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

table thead .dropup .caret, table thead .navbar-fixed-bottom .dropdown .caret {
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid \9;
  content: "";
}
